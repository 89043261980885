import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  viewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { SearchComponent } from '@components/ui/search/search.component';

@Component({
  selector: 'app-drawer',
  imports: [MatSidenavModule, MatIcon, MatToolbarModule, SearchComponent],
  templateUrl: './drawer.component.html',
  styleUrl: './drawer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent {
  searchDrawer = viewChild<SearchComponent>('searchDrawer');

  title = input.required<string>();
  searchPlaceholder = input<string>('Введите значение');

  withoutSearch = input<boolean>(false);

  searchText = output<string>();

  opened = input.required<boolean>();

  closeClick = output();
  closeClickFunction() {
    this.closeClick.emit();
  }

  clearSearch() {
    this.searchDrawer()?.clear();
  }
}

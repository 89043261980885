<mat-drawer-container class="drawer-container">
  <mat-drawer
    #drawer
    class="drawer"
    mode="side"
    position="end"
    [opened]="opened()"
    (closed)="clearSearch()"
  >
    <mat-toolbar class="header">
      <div class="title-block" [class.withoutSearch]="withoutSearch()">
        <div class="title">{{ title() }}</div>
        <mat-icon class="close" (click)="closeClickFunction()">close</mat-icon>
      </div>
      @if (searchPlaceholder() && !withoutSearch()) {
        <app-search
          #searchDrawer
          [placeholder]="searchPlaceholder()"
          variety="drawer"
          (searchText)="searchText.emit($event)"
        />
      }
      <ng-content select="[header]" />
    </mat-toolbar>

    <div class="contents"><ng-content select="[content]" /></div>

    <mat-toolbar class="footer"><ng-content select="[footer]" /></mat-toolbar>
  </mat-drawer>
</mat-drawer-container>

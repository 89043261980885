import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SearchComponent } from '../search/search.component';
import { DeclensePipe } from '@pipes/declense.pipe';
import { ButtonComponent } from '../button/button.component';
import { DrawerComponent } from '@components/common/drawer/drawer.component';
import { TFormattedApiError } from '@utils/formattingApiError';

import { UIService } from '@services/ui.service';
import { PriorityService } from '@components/pages/profile/profilePages/priority/core/priority.service';
import { NotificationService } from '@services/notification.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-select-location',
  imports: [
    OverlayModule,
    SearchComponent,
    MatIconModule,
    ButtonComponent,
    MatCheckboxModule,
    MatTooltipModule,
    DrawerComponent,
    ReactiveFormsModule,
    DeclensePipe,
    NgIf,
  ],
  templateUrl: './select-location.component.html',
  styleUrl: './select-location.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLocationComponent {
  searchRegion = viewChild<SearchComponent>('searchRegion');
  regionDrawer = viewChild<DrawerComponent>('regionDrawer');

  constructor(
    private priorityService: PriorityService,
    private uiService: UIService,
    private notification: NotificationService,
    private authService: AuthService,
  ) {}

  selectLocationTitle = this.priorityService.selectLocationTitle;
  isKnownUser = this.authService.isKnownUser;

  locationsList = this.priorityService.listSelectedRegions;
  filterLocationsList = signal(this.locationsList());
  selectedLocationsCount = signal(
    this.locationsList().filter((item) => item.is_selected).length,
  );

  loading = signal(false);
  isOpen = signal(false);
  toggleIsOpen(): void {
    this.isOpen.update((prev) => !prev);

    if (!this.isOpen()) this.filterLocationsList.set(this.locationsList());

    if (this.isMobile()) this.uiService.toggleMainScroll();
  }

  allRegions = signal<boolean>(false);

  destroyRef = inject(DestroyRef);
  isMobile = this.uiService.isMobile;

  locationForm: FormGroup = new FormGroup({});

  // Обновление списка регионов при получении нового списка с бэка
  changeRegions = effect(() => {
    this.filterLocationsList.set(this.locationsList());
    this.selectedLocationsCount.set(
      this.locationsList().filter((item) => item.is_selected).length,
    );

    const tempLocations: Record<string, FormControl> = {};
    this.locationsList().forEach((location) => {
      tempLocations[location.id] = new FormControl<boolean>(
        location.is_selected,
      );
    });

    this.locationForm = new FormGroup(tempLocations);
    this.locationForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((values) => {
        this.selectedLocationsCount.set(
          Object.keys(values)?.filter((key) => values[key]).length,
        );
      });
  });

  chageAllSelected = effect(() => {
    this.allRegions.set(
      this.selectedLocationsCount() === this.locationsList().length,
    );
  });

  onSearch(search: string) {
    if (search) {
      this.filterLocationsList.set(
        this.locationsList()?.filter((region) =>
          region.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
        ) || [],
      );
    } else {
      this.filterLocationsList.set(this.locationsList() || []);
    }
  }

  toggleAllRegions() {
    this.allRegions.update((prev) => !prev);

    this.searchRegion()?.clear();
    this.regionDrawer()?.clearSearch();

    if (this.allRegions()) {
      const locations = this.locationForm.value;
      Object.keys(locations)?.map((key) => (locations[key] = true));

      this.locationForm.patchValue(locations);
    } else {
      this.locationForm.reset();
    }
  }

  submitLocationForm() {
    this.loading.set(true);

    const formValues = this.locationForm.value;
    const ids = Object.keys(formValues)
      ?.filter((key) => formValues[key])
      .map((key) => Number(key));

    const requestData =
      ids.length == this.priorityService.listSelectedRegions().length
        ? {
            region_ids: [],
            is_all_regions: true,
          }
        : {
            region_ids: ids,
            is_all_regions: false,
          };

    this.priorityService.setListSelectedRegions(requestData).subscribe({
      next: () => {
        this.loading.set(false);

        this.priorityService.loadListSelectedRegions();

        if (this.isOpen()) this.toggleIsOpen();
      },
      error: (error: TFormattedApiError) => {
        this.loading.set(false);
        if (this.isOpen()) this.toggleIsOpen();

        if (error.formattedErrorMessage)
          this.notification.error(error.formattedErrorMessage);
      },
    });
  }

  resetLocationForm() {
    this.locationForm.reset();
    this.allRegions.set(false);
  }
}

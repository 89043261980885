import { NgClass } from '@angular/common';
import {
  Component,
  DestroyRef,
  OnInit,
  computed,
  effect,
  inject,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-search-with-button',
  imports: [NgClass, ReactiveFormsModule, MatIcon],
  templateUrl: './search-with-button.component.html',
  styleUrl: '../search.component.scss',
})
export class SearchWithButtonComponent implements OnInit {
  variety = input<'light' | 'dark' | 'drawer' | 'select' | 'priority'>('light');
  placeholder = input<string>('Введите значение');
  initialValue = input<string>('');
  disabledEmpty = input<boolean>(true);

  destroyRef = inject(DestroyRef);

  iconSearchColor = computed(() => {
    return this.variety() === 'dark' ? '#ffffff' : '#050609';
  });

  value = this.initialValue();
  readonly control = new FormControl<string>(this.value, {
    nonNullable: true,
    validators: [Validators.maxLength(255)],
  });

  inputValue = toSignal(this.control.valueChanges);
  disabledSearchButton = computed(() => {
    if (!this.disabledEmpty()) {
      return false;
    }
    const temp = this.inputValue();

    return !temp || temp.trim().length < 1;
  });

  changeInitialValue = effect(() => {
    if (this.initialValue()) {
      this.control.setValue(this.initialValue());
    }
  });

  searchValue = output<string>();

  searchText = output<string>();
  searchClick(event: Event, value: string) {
    event.preventDefault();

    if (!this.disabledSearchButton()) this.searchText.emit(value);
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.searchValue.emit(value);
      });
  }

  clear() {
    this.control.setValue('');
  }
}
